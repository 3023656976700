<template>
<ModuleMaster 
    :requireSearchField="false"
    :requireAddButton="getCanEdit"
    :requirePaginator="true"
    :labels="labels"
    :numItemsPaginator="totalRecords"
    :selectedPage="selectedPage"
    :itemsPerPage="5"
    :fnCallbackChangePage="handleChangePage"
    :fnCallbackCrudAction="handleCrudAction"
    :fnCallbackSearchItems="searchItems"
    :requireBackLink="true"
    backLink="/movements/inputs"
    
>
    <template #extra-buttons>
        <div class="extrabtns">            
            <div 
                v-if="showOptCF" 
                class="btn-padd-left"
                data-bs-toggle="tooltip" 
                data-bs-placement="bottom" 
                title="Captura Final de la Entrada" 
            >
                <a class="btn btn-icon btn-info" @click="onClickExtraBtn('CF')">
                    <span class="svg-icon svg-icon-1">
                        <inline-svg src="media/icons/duotune/general/gen047.svg" />
                    </span>
                </a>
            </div>
            <div 
                class="btn-padd-left"
                data-bs-toggle="tooltip" 
                data-bs-placement="bottom" 
                title="Imprimir" 
            >
                <a class="btn btn-icon btn-info" @click="print()">
                    <span class="svg-icon svg-icon-1">
                        <i class="bi bi-printer-fill fs-4"></i>
                    </span>
                </a>
            </div>
        </div>
        
    </template>
    <Datatable :headers="headers" :requireActions="true" :requireStatusCol="false">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireStatusCol="false"
            :statusPropName="'status'"
        >
            <template v-slot:productName>
                <ArticleInfo 
                    :product-name="row.Cells[0].Value"
                    :color="getColorName(row.ID)"
                    :code="getCode(row.ID)"
                ></ArticleInfo>
            </template>
            
            <CrudActions :itemID="row.ID" :callbackCrudAction="handleCrudAction" :requireM="getCanEdit" :requireE="false"/>
            <AditionalActions 
                :fnCallback="handleAditionalActionClick"
                :rowId="row.ID"
                :requireDelete="getCanEdit"
            />
        </Datarow>
    </Datatable>
</ModuleMaster>
<button hidden data-bs-toggle="modal" data-bs-target="#modalDetailInputEditForm" ref="btnForm"></button>
<DetailInputEditForm ref="form" :mode="modeEditForm" :title="titleEditFormAction" :currentOpenedItem="currentArticle" :fnCallBackPersistenceNotifier="handleItemSaved" :MovementId="getMovementId"/>
<button hidden ref="openPDF" data-bs-toggle="modal" data-bs-target="#viewPDF" />
<PDFViewer :base64="pdfMovement" titulo="Movimiento de Salida" ></PDFViewer>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue';
import ModuleMaster from "../../../components/c-module-master/module-master.vue";
import CrudActions from "../../../components/c-module-master/crud-actions.vue";
import Datatable from "../../../components/c-datatable/Datatable.vue";
import Datarow from "../../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../../common/formaterToDatatable";
import DetailInputEditForm from "./DetailInputEditForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import AditionalActions from "../common/aditional-actions.vue";
import { extractParamValue, getBooleanValue } from "../../../common/utils";
import ArticleInfo from '@/components/shared/articles/ArticleInfo.vue'
// import inputsTableModule from "@/store/modules/werehouse/modules/inputs-table"
import  PDFViewer from "@/views/cashregister/components/PDFViewer.vue";

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        CrudActions,
        DetailInputEditForm,
        AditionalActions 
        , ArticleInfo
        , PDFViewer
    },
    props: {
        inputId: {
            type: String,
            required: true
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: () => true
        },
        movementFolio: {
            type: String,
            required: false,
            default: () => ""
        }
    },
    setup(props) {
        //VARIABLES Y CONSTANTES
        const messageService = new SwalMessageService();
        let records = ref([]);
        let modeForm = ref("N");
        let currentPage = ref(1);
        const cantRecordsPage = 5;
        let totalItems = ref(0);
        const btnForm = ref(null);
        const form = ref(null);
        const currentItem = ref(null);
        let textSearch = ref("");
        let inputId = ref("");
        let canModify = ref(true);
        let movFolio = ref("");
        let requireCI = ref(false);
        let requireCF = ref(false);
        let requireAWM = ref(false);
        
        const controller = "inputs";
        let titleEditForm = ref("Nuevo Detalle de Entrada");
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Nuevo Registro",
        });
        const arrayTitleActions = [
            { action: "N", title: "Nuevo Detalle de Entrada" },
            { action: "M", title: "Modificar Detalle de Entrada" },
            { action: "V", title: "Visualizar Detalle de Entrada" }
        ];
        
        const configTable = reactive([
            //{ PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false },
            { PropName: "productName", HeadLabel: "Artículo", Type: "slot", VisibleInGrid: true },
            { PropName: "quantity", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },
            { PropName: "price", HeadLabel: "Precio", Type: "moneda", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true }
        ]);

        const openPDF = ref();
        let pdfMovement = ref("");

        //HOOKS
        onBeforeMount(async() => {
            if(props) {
                inputId.value = extractParamValue(atob(props.inputId), "inputId");
                canModify.value = true; //getBooleanValue(extractParamValue(atob(props.inputId), "canEdit"));
                movFolio.value = extractParamValue(atob(props.inputId), "movementFolio");
                requireCI.value = getBooleanValue(extractParamValue(atob(props.inputId), "requireCI"));
                requireCF.value = getBooleanValue(extractParamValue(atob(props.inputId), "requireCF"));
                requireAWM.value = getBooleanValue(extractParamValue(atob(props.inputId), "requireAWM"));
            }
            
            await getPagedItems();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs(`Detalles de Entrada${movFolio.value.trim().length > 0 ? " " : ""}${movFolio.value}`, ["Módulos", "Almacén", { name: "Entrada", to: "warehouse/adminInputs"}]);
        });

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable, "status");
        });

        const currentArticle = computed(() => {
            return currentItem.value;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const totalRecords = computed(() => {
            return totalItems.value;
        });

        const selectedPage = computed(() => {
            return currentPage.value;
        });

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        const getCanEdit = computed(() => {
            return canModify.value;
        });

        const getMovementId = computed(() => {
            return inputId.value;
        });

        const showOptCI = computed(() => requireCI.value);
        const showOptCF = computed(() => requireCF.value && records.value.length > 0);
        const showOptAWM = computed(() => requireAWM.value);

        //FUNCIONES
        const handleChangePage = async(page) => {
            await goToPage(page);
        }

        const handleCrudAction = async(action, itemID) => {
            modeForm.value = action;
            if(["N", "V", "M"].some(a => a === action)) {
                titleEditForm.value = arrayTitleActions.find(ac => ac.action == action).title;
                if(action === "M" || action === "V") {
                    await getArticleById(itemID);
                } else {
                    currentItem.value = undefined;
                }
                if(btnForm.value) {
                    form.value.initDefaults();
                    btnForm.value.click();
                }
            } else if(action === "E") {
                let item = records.value.find(i => i.id === itemID);
                if(item) {
                    Swal.fire({
                        title: `Está a punto de ${ item.status ? "desactivar" : "activar"} el registro. ¿Desea continuar?`,
                        icon: "warning",
                        showDenyButton: false,
                        showCancelButton: true,
                        confirmButtonText: 'Sí',
                        cancelButtonText: 'No',
                        denyButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                changeStatusItem(item);
                            }
                        }
                    );
                    
                }
                
            }
        }

        const changeStatusItem = async(item) => {
            let activate = !item.status;
            let url = `products/${item.id}/${activate ? 'activate' : 'deactivate'}`;
            await ApiService.put(url).then(res => {
                item.status = activate;
                handleItemSaved(item);
                messageService.success(`El registro se ${activate ? 'activó' : 'desactivó'} exitosamente.`);          
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const searchItems = async(text) => {
            currentPage.value = 1;
            textSearch.value = text;
            await getPagedItems();
        }

        const getPagedItems = async() => {
            let criteriaSearch = `Criteria.MovementId=${inputId.value}&Criteria.Active=true${textSearch.value.trim() != "" ? `&Criteria.Name=${textSearch.value.trim()}` : ""}`;
            let url = `${controller}/detail/GetPagedList/${inputId.value}?currentPage=${currentPage.value}&PageSize=${cantRecordsPage}&${criteriaSearch}`;
            ApiService.query(url).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getArticleById = async(id) => {
            ApiService.get(`${controller}/detail/${id}`).then(res => {
                currentItem.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleItemSaved = (item) => {
            switch(modeForm.value) {
                case "N":
                    if(currentPage.value > 1) {
                        goToPage(1);
                    } else {
                        ApiService.get(`${controller}/detail/${item.id}`).then(res => {
                            if(records.value.length == cantRecordsPage){
                                records.value = [res.data, ...records.value.slice(0, cantRecordsPage - 1)];
                            } else {
                                records.value = [res.data, ...records.value];
                            }
                            totalItems.value += 1;    
                        }).catch(reject => {
                            getMessageError(reject, messageService);
                        });                  
                    }
                    break;
                case "M":
                    updateRowItem(item);
                    break;
                case "E":
                     updateStatusRow(item.Id, item.status);
                    break;
            }
        }

        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        const updateRowItem = (item) => {
            let itemRow = records.value.find(ir => ir.id === item.Id);
            if(itemRow) {
                itemRow.productName = item.productName;
                itemRow.quantity = item.Quantity;
                itemRow.price = item.Price;
                itemRow.discountRate = item.DiscountRate;
            }
        }

        const updateStatusRow = (idRow, status) => {
            let itemRow = records.value.find(ir => ir.id === idRow);
            if(itemRow) {
                itemRow.status = status;
            }
        }

        const handleAditionalActionClick = (action, detailId) => {
            switch(action) {
                case "DEL":
                    deleteDetail(detailId);
                    break;
            } 
        }

        const deleteDetail = (detailId) => {
            let item = records.value.find(i => i.id === detailId);
            if(item) {
                Swal.fire({
                    title: `Está a punto de eliminar el registro. ¿Desea continuar?`,
                    icon: "warning",
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText: 'Sí',
                    cancelButtonText: 'No',
                    denyButtonText: 'No',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            ApiService.delete(`${controller}/${item.id}/delete`).then(res => {
                                if(res.status == 204){
                                    records.value = records.value.filter(r => r.id != item.id);
                                    totalItems.value = records.value.length;
                                }     
                            }).catch(reject => {
                                getMessageError(reject, messageService);
                            }); 
                        }
                    }
                );
            }
        }

        const onClickExtraBtn = (action) => {
            switch(action) {
                case "CI":
                    approvalMovement(inputId.value, "Initial", "inputs", "Está a punto de reabrir la captura del movimiento de entrada.");
                    break;
                case "CF":
                    approvalMovement(inputId.value, "EndCapture", "inputs", "Está a punto de finalizar la captura del movimiento de entrada.");
                    break;
                case "AWM":
                    approvalMovement(inputId.value, "approveByTheWarehouseManager");
                    break;
            }
        }

        const approvalMovement = (movementeId, approvalType, controllerAp = "inputs", messageTitle = "Está a punto de autorizar el movimiento.") => {
            Swal.fire({
                title: `${messageTitle} ¿Desea continuar?`,
                icon: "warning",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.put(`${controllerAp}/${movementeId}/${approvalType}`).then(res => {
                            if(res.status == 204){
                                let message = getApprovalMessage(approvalType);
                                updateStatusFlags(approvalType);
                                messageService.success(message);                         
                            }     
                        }).catch(reject => {
                            getMessageError(reject, messageService);
                        }); 
                    }
                }
            );
        }

        const getApprovalMessage = (approvalType) => {
            let approvalMsg = approvalMessages.find(am => am.type == approvalType);
            if(approvalMsg == null) {
               approvalMsg = approvalMessages.find(am => am.type == "default");
            }
            return approvalMsg ? approvalMsg.message : "El cambio de etapa se realizó de manera correcta.";
        }

        const approvalMessages = [
            { type: "EndCapture", message: "La captura del movimiento de entrada ha finalizado de menera correcta." },
            { type: "Initial", message: "La captura del movimiento de entrada ha sido reabierta de menera correcta." },
            { type: "default", message: "El movimiento de entrada fue autorizado de manera correcta." }
        ];

        const updateStatusFlags = (approvalType) => {
            switch(approvalType){
                case "Initial":
                    requireCI.value = false;
                    requireCF.value = true;
                    requireAWM.value = false;
                    break;
                case "EndCapture":
                    requireCI.value = true;
                    requireCF.value = false;
                    requireAWM.value = true;
                    break;
                case "approveByTheWarehouseManager":
                    requireCI.value = false;
                    requireCF.value = false;
                    requireAWM.value = false;
                    break;
            }
        }

        const print = () => { 
            ApiService.get(`${controller}/GetReceipt/${inputId.value}`)
            .then( resp => {
                if(resp.status == 200 && openPDF.value) {
                    pdfMovement.value = `data:application/pdf;base64,${resp.data}`;
                    openPDF.value.click();
                }
            }).catch(error => {
                getMessageError(error, messageService);
            });
        }

        const getColorName = (detId) => {
            let tmp = records.value.find(det => det.id == detId);
            return tmp ? tmp.colorName.trim() : ""
        }

        const getCode = (detId) => {
            let tmp = records.value.find(det => det.id == detId);
            return tmp ? tmp.code.trim() : ""
        }

        return {
            //variables y constantes
            labels,
            btnForm,
            form,
            pdfMovement,
            openPDF,

            //varaibles computadas
            headers,
            renderRows,
            titleEditFormAction,
            totalRecords,
            selectedPage,
            currentArticle,
            modeEditForm,
            getCanEdit,
            getMovementId,
            showOptCI,
            showOptCF,
            showOptAWM,

            //funciones
            handleChangePage,
            handleCrudAction,
            handleItemSaved,
            searchItems,
            handleAditionalActionClick,
            onClickExtraBtn,
            print,
            getColorName,
            getCode
        }
        
    },
})
</script>

<style>
    .extrabtns {
        display: flex;
        padding-right: 10px;
    }

    .btn-padd-left {
        padding-left: 10px;
    }
</style>